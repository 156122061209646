body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #111;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.container {
  max-width: 800px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 255, 0, 0.5);
}
h1 {
  font-size: 3em;
  color: #00ff00;
  margin-bottom: 0.5em;
}
p {
  font-size: 1.2em;
  color: #ccc;
}
.coming-soon {
  font-size: 1.5em;
  margin-top: 1.5em;
  color: #00ff00;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
