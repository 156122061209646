.pause-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pause-menu {
  background-color: #2a2a2a;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.pause-menu h2 {
  color: #fff;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.pause-menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pause-menu button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: #4a4a4a;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pause-menu button:hover {
  background-color: #666;
}
