.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-screen,
.menu-screen,
.settings-screen,
.battle-screen {
  text-align: center;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.battle-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #f5f5f5;
}

.game-field {
  flex: 1;
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, #87CEEB, #E0F6FF);
  overflow: hidden;
}

.tower {
  position: absolute;
  bottom: 0;
  width: 80px;
  height: 200px;
  background-color: #4a4a4a;
}

.player-tower {
  right: 0;
}

.enemy-tower {
  left: 0;
}

.unit {
  position: absolute;
  bottom: 0;
  width: 120px;
  height: 120px;
  transition: left 0.1s linear;
  background-color: transparent;
}

.player-unit {
  background-color: transparent;
}

.enemy-unit {
  background-color: transparent;
}

.unit-menu {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  height: 80px;
}

.money-display {
  color: #FFD700;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 2rem;
  min-width: 120px;
}

.unit-menu button {
  padding: 0.5rem 1rem;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 40px;
  min-width: 100px;
}

.unit-menu button:hover:not(:disabled) {
  background-color: #666;
}

.unit-menu button:disabled {
  background-color: #333;
  cursor: not-allowed;
  opacity: 0.7;
}

.health-bar {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  border-radius: 2px;
  overflow: hidden;
}

.health-fill {
  height: 100%;
  background-color: rgba(76, 175, 80, 0.9);
  transition: width 0.3s ease;
}

.tower .health-bar {
  top: -15px;
  height: 10px;
}

.pause-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 1000;
}

.pause-menu button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 200px;
}

.pause-menu button:hover {
  background-color: #666;
}

.battle-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 10px;
}

.stats-container {
  display: grid;
  gap: 1rem;
  margin: 2rem 0;
}

.stat-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.health-bar {
  transition: width 0.3s ease-in-out;
}

.win-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.win-screen h1 {
  color: #00ff00;
  font-size: 4em;
  margin-bottom: 1em;
  text-shadow: 0 0 10px #00ff00;
}

.win-screen button {
  padding: 1em 2em;
  font-size: 1.2em;
  background-color: #00ff00;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.win-screen button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #00ff00;
}

.lose-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.lose-screen h1 {
  color: #ff0000;
  font-size: 4em;
  margin-bottom: 1em;
  text-shadow: 0 0 10px #ff0000;
}

.lose-screen button {
  padding: 1em 2em;
  font-size: 1.2em;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.lose-screen button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #ff0000;
}

.shop-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
}

.shop-screen h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.shop-items {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.shop-items::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.shop-item {
  flex: 0 0 300px;
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  scroll-snap-align: start;
}

.shop-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.shop-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.unit-stats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.unit-stats p {
  margin: 0;
  padding: 0.5rem;
  background-color: #f8f8f8;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #666;
}

.back-button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 2rem;
}

.back-button:hover {
  background-color: #666;
}

.shop-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 5px;
  width: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  font-size: 1.5rem;
  transition: background-color 0.3s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.scroll-button:hover {
  background-color: #666;
}

.scroll-left {
  left: 0;
  border-radius: 0 5px 5px 0;
}

.scroll-right {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.shop-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.shop-info {
  display: flex;
  gap: 2rem;
  font-size: 1.2rem;
}

.currency {
  color: #4a4a4a;
  font-weight: bold;
}

.team-size {
  color: #4a4a4a;
}

.shop-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.purchase-button, .owned-button {
  margin-top: 1rem;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

.purchase-button {
  background-color: #4CAF50;
  color: white;
}

.purchase-button:hover:not(.disabled) {
  background-color: #45a049;
}

.purchase-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.owned-button {
  background-color: #666;
  color: white;
  cursor: default;
}

.team-display {
  margin-top: 2rem;
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.team-display h2 {
  margin-bottom: 1rem;
  color: #333;
}

.team-units {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.team-unit {
  padding: 0.5rem 1rem;
  background-color: #4a4a4a;
  color: white;
  border-radius: 5px;
  font-size: 0.9rem;
}

.team-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: black;
  color: white;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 2rem 0;
  padding: 1rem;
}

.team-unit-card {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-unit-card:hover {
  transform: translateY(-5px);
}

.team-unit-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.team-unit-card .unit-stats {
  display: grid;
  gap: 0.5rem;
}

.team-unit-card .unit-stats p {
  color: #666;
  margin: 0;
  font-size: 1.1rem;
}
